import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide", "slider", "pagerCurrent", "pagerTotal"]
  static values = {
    current: { type: Number, default: 0 }
  }

  connect() {
    this.#updatePager()
  }

  next() {
    if (this.currentValue >= this.slideTargets.length - 1) return
    this.currentValue++
  }

  previous() {
    if (this.currentValue <= 0) return
    this.currentValue--
  }

  slideTargetConnected() {
    this.#updatePager()
  }

  slideTargetDisconnected() {
    this.#updatePager()
  }

  currentValueChanged() {
    this.#updatePager()
    this.sliderTarget.scrollTo({
      left: this.currentSlide.offsetLeft,
      behavior: "smooth"
    })
  }

  get currentSlide() {
    return this.slideTargets[this.currentValue]
  }

  #updatePager() {
    this.pagerCurrentTarget.textContent = this.currentValue + 1
    this.pagerTotalTarget.textContent = this.slideTargets.length
  }
}
