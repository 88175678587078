import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ["form"]
  static debounces = ["submit"]

  connect() {
    useDebounce(this)
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}
